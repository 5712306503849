<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row mb-3">
          <div class="col-sm-2">
            <button
              class="btn btn-block btn-light"
              @click="openModalCategoriaTratamiento"
            >
              {{nombreTratamiento}}
            </button>
            <ModalCategoriaTratamiento @created="setDataGivenByTratamiento($event)"/>
          </div>
          <div class="col-sm-5">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Internación:</b>
              Esto se usa para poder crear diferentes usos en la internación.
            </p>
          </div>
          <div class="col-sm-2">
            <b-input-group>
              <input
                v-model="nombreTratamiento"
                @keyup.enter="guardarNombreTratamiento"
                type="text"
                class="form-control"
                placeholder=""
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="guardarNombreTratamiento"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-2">
            <TheMultiModalMedico
              :title="especialidad.title"
              :holder="especialidad.holder"
              :classButton="especialidad.classButton"
              :nameUrl="especialidad.nameUrl"
              :modal="especialidad.modal"
              :data="especialidad.data"
            />
          </div>
          <div class="col-sm-8">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Médico:</b>
              Esta función está relacionada con un cargo o especialidad
              que el médico tiene o asignado.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-2">
            <TheMultiModalMedico
              :title="clinicaExterna.title"
              :holder="clinicaExterna.holder"
              :classButton="clinicaExterna.classButton"
              :nameUrl="clinicaExterna.nameUrl"
              :modal="clinicaExterna.modal"
              :data="clinicaExterna.data"
            />
          </div>
          <div class="col-sm-8">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Paciente:</b>
              Se usa como referencia de donde viene o si es sugerido de otra clínica.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-2">
            <TheMultiModalMedico
              :title="medicoExterno.title"
              :holder="medicoExterno.holder"
              :classButton="medicoExterno.classButton"
              :nameUrl="medicoExterno.nameUrl"
              :modal="medicoExterno.modal"
              :data="medicoExterno.data"
            />
          </div>
          <div class="col-sm-8">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Paciente:</b>
              Se usa en paciente también para hacer referencia que medico
              lo recomendó y que no está en nuestra BD.
            </p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-2">
            <button
              @click="openModalCategoriaMedico"
              class="btn btn-block btn-light"
            >
              Categoria Medico
            </button>
            <ModalCategoriaMedico/>
          </div>
          <div class="col-sm-8">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Médico:</b>
              Está relacionado en “Honorario” cuando se relaciona con un servicio,
              la misma que se usa para Internación.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-2">
            <button
              @click="openModalCategoriaSeguro"
              class="btn btn-block btn-light"
            >
              Categoria Seguro
            </button>
            <ModalCategoriaSeguro ref="modalCategoriaSeguro"/>
          </div>
          <div class="col-sm-8">
            <p for="servicios_complementarios" class="col-form-label">
              <b>Categoría Seguro:</b>
              Es para poder diferenciar que tipo de seguro o acuerdo tiene con las ASEGURADORAS.
            </p>
          </div>
        </div>
        <div class="row ml-2 ">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="checkDobleSeguro"
              @change="updateDobleSeguro"
              id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              <b>Venta:</b> Se Habilita en Venta Clinica el doble seguro u uso doble seguro
            </label>
          </div>
        </div>
        <div class="row ml-2 mb-5">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="checkInternacion"
              @change="updateInternacion"
              id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              <b>Internacion:</b> Es la funcion de H.C Internacion
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

import ModalCategoriaTratamiento from '@/modules/parametros/components/internacion/TheModalCategoriaTratamiento.vue';
import ModalCategoriaMedico from '@/modules/parametros/components/medico/TheModalCategoriaMedico.vue';
import TheMultiModalMedico from '@/modules/parametros/components/medico/TheMultiModalMedico.vue';
import ModalCategoriaSeguro from '@/modules/parametros/components/seguro/TheModalCategoriaSeguro.vue';

export default {
  name: 'TheOtros',
  components: {
    ModalCategoriaTratamiento,
    ModalCategoriaMedico,
    TheMultiModalMedico,
    ModalCategoriaSeguro,
  },
  data() {
    return {
      especialidad: {
        title: 'Especialidad',
        holder: 'Ingrese una Especialidad',
        classButton: 'btn btn-block btn-light',
        nameUrl: 'especialidad',
        modal: 'modal-expecialidad',
        data: 'especialidad',
      },
      clinicaExterna: {
        title: 'Clinica Externa',
        holder: 'Ingrese una Clinica Externa',
        classButton: 'btn btn-block btn-light',
        nameUrl: 'clinicaexterna',
        modal: 'modal-clinicaExterna',
        data: 'clinica_externa',
      },
      medicoExterno: {
        title: 'Medico Externo',
        holder: 'Ingrese un Medico Externo',
        classButton: 'btn btn-block btn-light',
        nameUrl: 'medicoexterno',
        modal: 'modal-medicoExterno',
        data: 'medico_externo',
      },
      nombreTratamiento: '',
      paramsFromModalTratamiento: null,
      checkDobleSeguro: false,
      checkInternacion: false,
    };
  },
  async mounted() {
    this.getParametrosOtros();
  },
  methods: {
    openModalCategoriaTratamiento() {
      this.$bvModal.show('modal-categoriaTratamiento');
    },
    openModalCategoriaMedico() {
      this.$bvModal.show('modal-categoriaMedico');
    },
    openModalCategoriaSeguro() {
      this.$bvModal.show('modal-categoriaSeguro');
    },
    setDataGivenByTratamiento(params) {
      if (params != null) {
        this.nombreTratamiento = params.nombre_tratamiento_principal ?? '';
      }
      this.paramsFromModalTratamiento = params;
    },
    async guardarNombreTratamiento() {
      try {
        let id = 0;
        let nombreTratamientoPrincipal = null;
        if (this.paramsFromModalTratamiento !== null) {
          id = this.paramsFromModalTratamiento.id;
          nombreTratamientoPrincipal = this.paramsFromModalTratamiento.nombre_diagnostico;
        }
        const REQUEST = {
          id,
          nombre_tratamiento_principal: this.nombreTratamiento,
          nombre: nombreTratamientoPrincipal,
          nombre_farmacia: null,
        };
        const RESPONSE = await axios.post('/clinic/parametros/nombre_diagnostico', REQUEST);
        this.setDataGivenByTratamiento(RESPONSE.data.data.nombre_parametro);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getParametrosOtros() {
      try {
        const response = await axios.get('/clinic/parametros/other/create');
        this.checkDobleSeguro = response.data.data.has_active_doble_seguro;
        this.checkInternacion = response.data.data.has_active_tratamiento_historial_clinico;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateDobleSeguro() {
      try {
        await axios.post('/clinic/parametros/other/activation', {
          doble_seguro: this.checkDobleSeguro,
        });
        util.showNotify('Doble seguro actualizado correctamente', 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateInternacion() {
      try {
        await axios.post('/clinic/parametros/other/activation', {
          tratamiento_historial_clinico: this.checkInternacion,
        });
        util.showNotify('Internacion actualizado correctamente', 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
</script>
