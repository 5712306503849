<template>
  <b-modal
    id="modal-interConsulta"
    size="lg"
    hide-footer
  >
    <template #modal-title>
      <b>Inter Consulta: {{ tabSolicitud.numero }}</b>
    </template>
    <div class="row">
      <div class="col-sm-12">
        <b-tabs content-class="px-0 pb-0">
          <b-tab
            title="Solicitud"
            active
          >
            <div class="row mb-2">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Solicitante:
                </label>
              </div>
              <div class="col-sm-5 pl-0">
                <multiselect
                  v-model="tabSolicitud.solicitanteSelected"
                  :options="tabSolicitud.listaSolicitantes"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="nombre"
                  select-label=""
                  deselect-label="X"
                  @select="solicitanteSelect"
                >
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.nombre }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.nombre }}
                  <span v-if="props.option.especialidad"
                    style="color: red; font-weight: bold">
                    ( {{props.option.especialidad }} )
                  </span>
                </template>
                  <span slot="caret" v-if="!(tabSolicitud.solicitanteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="offset-sm-1 col-sm-2 text-right">
                <label class="col-form-label">
                  Fecha:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <date-picker
                  class="w-100"
                  v-model="tabSolicitud.fecha"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Destinatario:
                </label>
              </div>
              <div class="col-sm-5 pl-0">
                <multiselect
                  v-model="tabSolicitud.destinatarioSelected"
                  :options="getMedicoDestinatarios"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="nombre"
                  select-label=""
                  deselect-label="X"
                >
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.nombre }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.nombre }}
                  <span v-if="props.option.especialidad"
                    style="color: red; font-weight: bold">
                    ( {{props.option.especialidad }} )
                  </span>
                </template>
                  <span slot="caret" v-if="!(tabSolicitud.destinatarioSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="offset-sm-1 col-sm-2 text-right">
                <label class="col-form-label">
                  Hora:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <input
                  v-model="tabSolicitud.hora"
                  type="time"
                  class="form-control"
                >
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-12 py-2 custom-bg-gray">
                <div class="row py-1">
                  <div class="col-sm-3">
                    <h5 class="mb-0 font-weight-bold">Estado de Salud</h5>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="radio" id="tabSolicitud_checkBueno"
                        value="1" name="tabSolicitud_valoracion"
                        v-model="tabSolicitud.valoracion">
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkBueno">
                        Bueno
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="radio" id="tabSolicitud_checkMalo"
                        value="2" name="tabSolicitud_valoracion"
                        v-model="tabSolicitud.valoracion"
                      >
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkMalo">
                        Malo
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="radio" id="tabSolicitud_checkRegular"
                        value="3" name="tabSolicitud_valoracion"
                        v-model="tabSolicitud.valoracion">
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkRegular">
                        Regular
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-0">
              <div class="col-sm-12 custom-bg-gray py-2">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Resumen Clínico:</b>
                      </div>
                      <div class="col-sm-12 pr-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          v-model="tabSolicitud.resumeClinico"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Atenciones Realizadas:</b>
                      </div>
                      <div class="col-sm-12 pl-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          v-model="tabSolicitud.atencionesRealizadas"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Evolución del Paciente:</b>
                      </div>
                      <div class="col-sm-12 pr-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          v-model="tabSolicitud.evolucionPaciente"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Motivo de la Inter Consulta:</b>
                      </div>
                      <div class="col-sm-12 pl-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          v-model="tabSolicitud.motivo"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <span
                  class="col-form-label text-secondary font-weight-bold cursor-pointer d-inline-block"
                >
                  Imprimir Solicitud
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success" type="button" @click="addInterConsulta">Guardar</button>
              </div>
            </div>
          </b-tab>
          <!-- TAB RESPUESTA -->
          <b-tab
            title="Respuesta"
            :disabled="!tabSolicitud.interconsulta_id"
          >
            <div class="row mb-2">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Solicitante:
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  v-model="tabRespuesta.solicitanteSelected"
                  :options="tabRespuesta.listaSolicitantes"
                  disabled
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="nombre"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabRespuesta.solicitanteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">
                  Fecha:
                </label>
              </div>
              <div class="col-sm-2 pl-0 pr-2">
                <date-picker
                  class="w-100"
                  v-model="tabRespuesta.fecha"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">
                  Hora:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <input
                  v-model="tabRespuesta.hora"
                  type="time"
                  class="form-control"
                >
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Destinatario:
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  v-model="tabRespuesta.destinatarioSelected"
                  :options="tabRespuesta.listaDestinatarios"
                  disabled
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="nombre"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabRespuesta.destinatarioSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
            </div>

            <div class="row mx-0">
              <div class="col-sm-12 custom-bg-gray py-2">
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <b>Informe:</b>
                  </div>
                  <div class="col-sm-12 pr-2">
                    <textarea
                      class="form-control resize-none border-dashed"
                      v-model="tabRespuesta.informe"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <b>Recomendación:</b>
                  </div>
                  <div class="col-sm-12 pr-2">
                    <textarea
                      class="form-control resize-none border-dashed"
                      v-model="tabRespuesta.recomendacion"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <span
                  class="col-form-label text-secondary font-weight-bold cursor-pointer d-inline-block"
                >
                  Imprimir Solicitud y Respuesta
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success" type="button" @click="guardarRespuesta">Guardar</button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { util } from '@/plugins/util';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ModalInterConsulta',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {};
  },
  methods: {
    addInterConsulta() {
      if (this.validar()) {
        let estadoSalud = null;
        switch (this.tabSolicitud.valoracion) {
          case '1':
            estadoSalud = 'Bueno';
            break;
          case '2':
            estadoSalud = 'Malo';
            break;
          case '3':
            estadoSalud = 'Regular';
            break;
          default:
            break;
        }

        if (this.tabSolicitud.isEdit) {
          this.interConsultas[this.tabSolicitud.index].atenciones_realizadas = this.tabSolicitud.atencionesRealizadas;
          this.interConsultas[this.tabSolicitud.index].motivo = this.tabSolicitud.motivo;
          this.interConsultas[this.tabSolicitud.index].evolucion_paciente = this.tabSolicitud.evolucionPaciente;
          this.interConsultas[this.tabSolicitud.index].resumen_clinico = this.tabSolicitud.resumeClinico;
          this.interConsultas[this.tabSolicitud.index].fecha = moment(this.tabSolicitud.fecha).format('YYYY-MM-DD');
          this.interConsultas[this.tabSolicitud.index].hora = this.tabSolicitud.hora;
          this.interConsultas[this.tabSolicitud.index].valoracion = this.tabSolicitud.valoracion;
          this.interConsultas[this.tabSolicitud.index].estado_salud = estadoSalud;

          this.interConsultas[this.tabSolicitud.index].solicitante_medico_id = this.tabSolicitud.solicitanteSelected.id;
          this.interConsultas[this.tabSolicitud.index].destinatario_medico_id = this.tabSolicitud.destinatarioSelected.id;
          this.guardarRespuesta();
        } else {
          const DATA = {
            interconsulta_id: null,
            fecha: moment(this.tabSolicitud.fecha).format('YYYY-MM-DD'),
            hora: this.tabSolicitud.hora,
            valoracion: this.tabSolicitud.valoracion,
            resumen_clinico: this.tabSolicitud.resumeClinico,
            atenciones_realizadas: this.tabSolicitud.atencionesRealizadas,
            evolucion_paciente: this.tabSolicitud.evolucionPaciente,
            motivo: this.tabSolicitud.motivo,
            estado_salud: estadoSalud,
            solicitante_medico_id: this.tabSolicitud.solicitanteSelected.id,
            destinatario_medico_id: this.tabSolicitud.destinatarioSelected.id,
            respuesta: null,
            numero: this.interConsultas.length + 1,
            isEdit: true,
          };
          this.interConsultas.push(DATA);
        }
        this.$bvModal.hide('modal-interConsulta');
        this.resetDataInterConsulta();
      }
    },
    validar() {
      let valido = true;
      if (!this.tabSolicitud.fecha) {
        util.showNotify('Seleccione una fecha', 'warn');
        valido = false;
      }
      if (!this.tabSolicitud.solicitanteSelected) {
        util.showNotify('Seleccione un solicitante', 'warn');
        valido = false;
      }
      if (!this.tabSolicitud.destinatarioSelected) {
        util.showNotify('Seleccione un destinatario', 'warn');
        valido = false;
      }
      if (!this.tabSolicitud.valoracion) {
        util.showNotify('Seleccione un estado de salud', 'warn');
        valido = false;
      }
      return valido;
    },
    guardarRespuesta() {
      // GUARDA RESPUESTA CUANDO YA SE GUARDO UNA SOLICITUD;
      const RESPUESTA = {
        respuesta_id: 0,
        fecha: moment(this.tabRespuesta.fecha).format('YYYY-MM-DD'),
        hora: this.tabRespuesta.hora,
        informe: this.tabRespuesta.informe,
        recomendacion: this.tabRespuesta.recomendacion,
      };
      this.interConsultas[this.tabSolicitud.index].respuesta = RESPUESTA;
      this.$bvModal.hide('modal-interConsulta');
    },
    resetDataInterConsulta() {
      this.tabSolicitud.solicitanteSelected = null;
      this.tabSolicitud.fecha = new Date();
      this.tabSolicitud.destinatarioSelected = null;
      this.tabSolicitud.hora = moment().format('HH:mm:ss');
      this.tabSolicitud.valoracion = null;
      this.tabSolicitud.resumeClinico = null;
      this.tabSolicitud.atencionesRealizadas = null;
      this.tabSolicitud.evolucionPaciente = null;
      this.tabSolicitud.motivo = null;
      this.tabSolicitud.respuesta = null;
      this.tabSolicitud.interconsulta_id = null;

      this.tabRespuesta.solicitanteSelected = null;
      this.tabRespuesta.fecha = new Date();
      this.tabRespuesta.destinatarioSelected = null;
      this.tabRespuesta.hora = moment().format('HH:mm:ss');
      this.tabRespuesta.importe = 0;
    },
    solicitanteSelect() {
      this.tabSolicitud.destinatarioSelected = null;
    },
  },
  computed: {
    ...mapFields('theModalInterConsulta', [
      'tabSolicitud',
      'tabRespuesta',
      'interConsultas',
    ]),
    ...mapGetters('theModalInterConsulta', [
      'getMedicoDestinatarios',
    ]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom-bg-gray {
  background: #eee;
}
.resize-none {
  resize: none;
}
.border-dashed{
  border-style: dashed;
  border-width: 1px;
  border-color: gray;
}
</style>
