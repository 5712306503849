<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
                <button
                  class="btn th-custom-color px-4 rounded ml-3 mr-2"
                  @click="irACrearSeguro"
                  :disabled="!hasPermisionNuevoSeguro"
                >
                  Nuevo
                </button>
                <button class="btn th-custom-color px-4 rounded mr-2"
                  @click="$router.push({ name: 'aseguradora-import' })">
                  Importar
                </button>
              </div>
              <div class="col-sm-3 offset-sm-4">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                     :checked="estado"
                     v-model="estado"
                     @change="listarAseguradoras">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select v-model="por_pagina" :options="pagina_opciones"
                  @change="listarAseguradoras"></b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" v-model="busqueda"
                    autocomplete="off" placeholder="Buscar" @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :fields="getFieldsTable"
              :items="listaTabla"
              :busy="isLoading"
              :per-page="por_pagina"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template v-if="estado" #cell(editar)="items">
                <router-link v-if="hasPermisionModificarSeguro && isNotVenta(items.item.cant_ventas)"
                 :to="{ name: 'aseguradora-edit', params: {id: encrypt(items.item.id)}}"
                >
                  <i
                    class="nav-icon i-Pen-5 text-success font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </router-link>
                <i v-else
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                  title="No disponible"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  v-if="hasPermisionVerSeguro"
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerSeguro? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template v-if="estado" #cell(eliminar)="items">
                <i
                  @click="onClickEliminarIcon(items.item)"
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarSeguro? 'text-danger':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(total_coa_seguro)="items">
                {{items.item.total_coa_seguro | numeral('0,0.00')}}
              </template>
              <template #cell(total_seguro)="items">
                {{items.item.total_seguro | numeral('0,0.00')}}
              </template>
            </b-table>
            <b-modal id="modal-eliminar"
              :title="'Seguro que quieres eliminar Aseguradora - N° '+selectedNro+'?'"
              hide-footer>
              <div class="row">
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                    Eliminar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-outline-dark"
                  @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
                </div>
              </div>
            </b-modal>
            <!-- Paginacion -->
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarAseguradoras"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <!-- Fin Paginacion -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import { FUNCIONALIDADES } from '@/utils/globalConventions';

export default {
  name: 'index',
  data() {
    return {
      isLoading: true,
      estado: true,
      busqueda: '',
      pagina_actual: 1,
      por_pagina: 100,
      pagina_opciones: [50, 100],
      sortBy: 'numero',
      sortDesc: true,
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'ver', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '6%' }, sortable: true,
        },
        {
          key: 'persona', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'contacto', label: 'Contacto', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'telefono', label: 'Celular', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'nit', label: 'Nit', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'cantidad', label: 'Servicios', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'total_coa_seguro', label: 'Total Coaseguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '13%' }, sortable: true,
        },
        {
          key: 'total_seguro', label: 'Total Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '11%' }, sortable: true,
        },
      ],
      listaTabla: [],
      totalFilas: 0,
      delayTimeout: null,
      selectedId: null,
      selectedNro: null,
    };
  },
  created() {
    this.listarAseguradoras();
  },
  methods: {
    irACrearSeguro() {
      this.$router.push({ name: 'aseguradora-create' });
    },
    filtrarBusqueda() {
      const delay = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarAseguradoras, delay);
    },
    async listarAseguradoras() {
      this.isLoading = true;
      try {
        const request = {
          state: this.estado,
          filterKey: this.busqueda,
          page: this.pagina_actual,
          per_page: this.por_pagina,
        };
        const response = await axios.get('/clinic/insurer', {
          params: request,
        });
        const arrayAseguradoras = response.data.data.insurers.data;
        this.totalFilas = response.data.data.insurers.total;
        this.listaTabla = arrayAseguradoras;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.listaTabla = this.listaTabla.filter((item) => (
          item.id !== this.selectedId
        ));
        const response = await axios.delete(`/clinic/insurer/${this.selectedId}`);
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarAseguradoras();
      }
    },
    encrypt(id) {
      return btoa(id);
    },
    onClickEliminarIcon(item) {
      if (this.hasPermisionEliminarSeguro) {
        this.setItems(item);
        this.$bvModal.show('modal-eliminar');
      }
    },
    isNotVenta(cantVentas) {
      return cantVentas === 0;
    },
  },
  computed:
  {
    ...mapGetters('main', [
      'getFuncionalidades',
      'hasPermisionNuevoSeguro',
      'hasPermisionModificarSeguro',
      'hasPermisionVerSeguro',
      'hasPermisionEliminarSeguro',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    getFieldsTable() {
      let customFields = this.fields;
      if (this.hasPermisionVerSeguro === false) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'ver'
        ));
      }
      return customFields;
    },
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.SEGURO);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
